import Quill from "quill";

document.addEventListener("turbolinks:load", () => {

  const toggles = document.querySelectorAll("[data-quill]");

  toggles.forEach((toggle) => {
    const elementOptions = toggle.dataset.quill ? JSON.parse(toggle.dataset.quill) : {};
    const defaultOptions = {};
    const options = { ...elementOptions, ...defaultOptions };

    var quill = new Quill(toggle, options);

    const targetId = toggle.getAttribute('quill-target');
    const target = document.getElementById(targetId);

    quill.setText(target.value);

    quill.on('text-change', function() {
      target.value = quill.getText();
    });

  });

  // Make available globally
  window.Quill = Quill;

});
