window.FilePond = require('filepond');
window.FilePondPluginImagePreview = require('filepond-plugin-image-preview');
window.FilePondPluginFileValidateSize = require('filepond-plugin-file-validate-size');
window.FilePondPluginFileValidateType = require('filepond-plugin-file-validate-type');

document.addEventListener("turbolinks:load", () => {

  FilePond.registerPlugin(
    FilePondPluginFileValidateSize,
    FilePondPluginFileValidateType,
    FilePondPluginImagePreview
  )

  document.querySelectorAll('.uploader').forEach(function(el) {

    var previewObject = el.getAttribute('preview');
    var previewUrl = el.getAttribute('previewUrl');

    var fileObject = el.getAttribute('file');
    var fileName = el.getAttribute('fileName');

    const inputFile = FilePond.create(el, {
      acceptedFileTypes: (el.getAttribute('accept') != null) ? el.getAttribute('accept') : 'image/*',
      labelIdle: (el.getAttribute('label') != null) ? el.getAttribute('label') : 'Drag & Drop your image or <span class="filepond--label-action">Browse</span>',
      maxFileSize: (el.getAttribute('maxSize') != null) ? el.getAttribute('maxSize') : '2000MB',
      imagePreviewMaxHeight: (el.getAttribute('previewHeight') != null) ? el.getAttribute('previewHeight') : 378,
      storeAsFile: true,
      credits: false,
    })

    if (previewObject || previewUrl) {
      if (previewObject) {
        inputFile.addFile(previewObject)
      } else {
        inputFile.addFile(previewUrl)
      }
    }

    function addMockFile(options) {
      inputFile.addFiles([
        {
          source: "preview",
          options: {
            type: 'local',
            file: options
          }
        }
      ])
    };

    if (fileObject || fileName) {
      if (fileObject) {
        const preview = JSON.parse(fileObject)
        addMockFile({ name: preview.filename, size: preview.byte_size })
      } else {
        addMockFile({ name: fileName, size: 0 })
      }
    }

  });
});
