window.YoutubeScheduling = {

  init: function() {
    
    new ModalForm('youtube_scheduling_youtube_presets_schedule_id')
    new ModalForm('youtube_scheduling_youtube_presets_metadata_id')
    new ModalForm('youtube_scheduling_youtube_presets_content_id')
    
    document.addEventListener("turbolinks:load", () => {

      new TomSelect('#youtube_scheduling_playlist_ids', {
        plugins: {
      		remove_button: {
      			title: 'Remove this item',
      		}
      	},
        onItemRemove(value) {
          document.querySelector('#youtube_scheduling_playlist_ids').tomselect.removeOption(value)
        }
      })
  
      new TomSelect('.playlists #playlists_select_channel', {
        maxOptions: 1000,
      	onChange(channel) {
  
          fetch('/youtube/playlists.json?youtube_playlists[youtube_channel_ids]='+channel).then(response => response.json()).then(json => {
  
            document.querySelector('.playlists #playlists_select_playlist').removeAttribute('disabled')
  
            if (typeof window.playlistSelect !== 'undefined') {
              window.playlistSelect.destroy()
            }
  
            window.playlistSelect = new TomSelect('.playlists #playlists_select_playlist', {
              options: json,
              valueField: 'id',
              labelField: 'title',
              maxOptions: 1000,
              onItemAdd(value, item) {
                window.playlistSelect.clear()
                document.querySelector('.playlists .ts-wrapper.multi').style.background = 'white'
                document.querySelector('.playlists .ts-wrapper.multi .ts-control').style.background = 'white'
                document.querySelector('#youtube_scheduling_playlist_ids').tomselect.addOption({value: value, text: item.innerHTML })
                document.querySelector('#youtube_scheduling_playlist_ids').tomselect.addItem(value)
              }
            })
          })
        }
      })
      
      if (document.querySelector('#youtube_scheduling_playlist_ids').value != '') {
        document.querySelector('.playlists .ts-wrapper.multi').style.background = 'white'
        document.querySelector('.playlists .ts-wrapper.multi .ts-control').style.background = 'white'
      }
    })
  }
}
