import clip from "text-clipper";

document.addEventListener("turbolinks:load", () => {

  if (document.getElementsByClassName("truncator").length > 0) {
    document.querySelectorAll(".truncator").forEach((el) => {

      const elementTextContent = el.getAttribute('data-content') || '';
      console.log(elementTextContent);
      const truncationThreshold = 140;
      const clippedTextContent = clip(elementTextContent, truncationThreshold, { html: true, maxLines: 5 });

      if (elementTextContent.length > (truncationThreshold - 1)) {
        const minusIcon = document.createElement("i");
        minusIcon.classList.add('fe', 'fe-minus-circle','small', 'ms-1', 'link-primary', 'clickable')

        minusIcon.onclick = function () {
          el.textContent = clippedTextContent;
          el.append(plusIcon);
        };

        const plusIcon = document.createElement("i");
        plusIcon.classList.add('fe', 'fe-plus-circle','small', 'ms-1', 'link-primary', 'clickable')

        plusIcon.onclick = function () {
          el.textContent = elementTextContent;
          el.append(minusIcon);
        };

        el.textContent = clippedTextContent;
        el.append(plusIcon);

      } else {
        el.textContent = clippedTextContent;
      };
    });
  };
});
