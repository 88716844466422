$(document).on("turbolinks:load", () => {
  if (document.getElementById('youtubePreviewModal')) {

    var youtubePreviewModal = document.getElementById('youtubePreviewModal')
    var embeddedYoutubePlayer = document.getElementById('embeddedYoutubePlayer')

    youtubePreviewModal.addEventListener('show.bs.modal', function (event) {
      var button = event.relatedTarget
      var video_id = button.getAttribute('data-video-id')

      embeddedYoutubePlayer.src = "https://www.youtube.com/embed/" + video_id
    })

    youtubePreviewModal.addEventListener('hide.bs.modal', function (event) {
      embeddedYoutubePlayer.src = ''
    })

  }
});
