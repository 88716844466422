window.ModalForm = class ModalForm {

  id = ''

  constructor(id) {
		this.id = id
		this.ajax = this.ajax.bind(this)
		const create = document.querySelector('#'+id).nextElementSibling
		create.addEventListener('click', this.ajax, false)
  }
  
  ajax() {
    
    const id = this.id
    const create = document.querySelector('#'+this.id).nextElementSibling
    const url = create.getAttribute('data-ajax')
    
    fetch(url).then(response => response.text()).then(data => {
      
      document.querySelector('.modal-content').innerHTML = data
      
      const script = document.querySelector('.modal-content script')
      if (script != null) eval(script.innerText)

      document.querySelector('.modal-content form').addEventListener('submit', function(e) {
        
        e.preventDefault()
        
        var xhttp = new XMLHttpRequest()
        xhttp.onreadystatechange = function() {
          
          if (this.readyState === 4 && this.status === 200) {
            
            const response = JSON.parse(xhttp.responseText)
            
            if (response.type == 'success') {
              document.getElementById(id).add(new Option(response.data.name, response.data.id))
              document.getElementById(id).value = response.data.id
              document.getElementById('modal').click()
            } else {
              document.querySelector('.modal-content .btn-primary').removeAttribute('disabled')
              alert(response.data[0])
            }
          }
        }
        xhttp.open('POST', this.getAttribute('action'), true)
        xhttp.setRequestHeader('X-Requested-With', 'XMLHttpRequest')
        xhttp.send(new FormData(this))
        
      }, true)
    })
  }
}