document.addEventListener("turbolinks:load", () => {

  const checkboxAll = document.getElementById("table-checkbox-all")
  const checkboxes = document.querySelectorAll(".table-checkbox-one")
  const bulkActionsDropdown = document.getElementById("bulk-actions")

  function refreshBulkActionsVisibility() {
    if (bulkActionsDropdown) {
      let checkedElements = document.querySelectorAll('.table-checkbox-one:checked');

      if (checkedElements.length > 0 ) {
        bulkActionsDropdown.classList.remove('d-none');
      } else {
        bulkActionsDropdown.classList.add('d-none');
        checkboxAll.checked = false;
      }
    };
  };

  if (bulkActionsDropdown) {
    bulkActionsDropdown.classList.add('d-none');

    checkboxes.forEach((checkbox) => {
      checkbox.addEventListener('click', function() {
        refreshBulkActionsVisibility();
      });
    });
  };

  if (checkboxAll) {
    checkboxAll.addEventListener('click', function(element) {
      checkboxes.forEach((checkbox) => {
        checkbox.checked = checkboxAll.checked
      });

      refreshBulkActionsVisibility();
    });
  }


});
