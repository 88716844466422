document.addEventListener("turbolinks:load", () => {

  if (document.getElementsByClassName("truncatable-tags").length > 0) {
    document.querySelectorAll(".truncatable-tags").forEach((el) => {
      const truncatables = el.querySelectorAll("[data-truncatable]")

      if (truncatables.length > 0) {
        const minusIcon = document.createElement("i");
        minusIcon.classList.add(
          'truncatable-minus', 'fe', 'fe-minus-circle','small', 'align-middle',
          'link-primary', 'clickable', 'd-none'
        );

        minusIcon.onclick = function () {
          truncatables.forEach((truncatable) => { truncatable.classList.toggle('d-none') });
          plusIcon.classList.toggle('d-none');
          minusIcon.classList.toggle('d-none');
        };

        const plusIcon = document.createElement("i");
        plusIcon.classList.add(
          'truncatable-plus', 'fe', 'fe-plus-circle','small', 'align-middle',
          'link-primary', 'clickable'
        );

        plusIcon.onclick = function () {
          truncatables.forEach((truncatable) => { truncatable.classList.toggle('d-none') });
          minusIcon.classList.toggle('d-none');
          plusIcon.classList.toggle('d-none');
        };

        truncatables.forEach((truncatable) => { truncatable.classList.toggle('d-none') });

        if (el.querySelectorAll('.truncatable-plus').length == 0) { el.append(plusIcon) };
        if (el.querySelectorAll('.truncatable-minus').length == 0) { el.append(minusIcon) };
      };
    });
  };

});
