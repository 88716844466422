window.WeekPicker = {

	months: ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'],
	year: 2022,
	input: null,
	weeks: [],

  init: function (id, year) {
		let num = 1
		let html = ''
		this.element = id
		this.input = document.getElementById(id)
		this.input.type = 'hidden'
		this.year = year
		this.months.forEach((item, index) => {
			const weeks = WeekPicker.weeksInMonth(WeekPicker.year, index)
		  html += '<div>'
		  html += '<div class="week">'+item+'</div>'
		  	html += '<div>'
		  		for (let i = 1; i <= weeks; i++, num++) {
		  			if (num <= 52) html += '<div class="week-number week-'+num+'" onclick="WeekPicker.select('+num+')">'+num+'</div>'
		  		}
		  	html += '</div>'
			html += '</div>'
		})
		if (this.input.parentNode.querySelector('.weeks-selectall')) {
			this.input.parentNode.querySelector('.weeks-selectall').remove()
		}
		const divAll = document.createElement('div')
		divAll.classList.add('weeks-selectall')
		divAll.innerHTML = 'Select all'
		divAll.onclick = function() {
      WeekPicker.selectAll()
    }
    this.input.parentElement.prepend(divAll)
		if (this.input.parentNode.querySelector('.weeks-container')) {
			this.input.parentNode.querySelector('.weeks-container').remove()
		}
		const div = document.createElement('div')
		div.classList.add('weeks-container')
		div.innerHTML = html
		this.input.parentNode.insertBefore(div, this.input.nextSibling)
		this.weeks = this.input.value != '' ? this.input.value.split(',').map(x => parseInt(x.trim())) : []
		this.display()
  },

  selectAll: function () {
    let nbWeeks = this.weeks.length
    this.weeks = []
    if (nbWeeks < 52) {
  	  for (let i = 1; i <= 52; i++) {
  			this.weeks.push(i)
  		}
    }
		this.display()
  },

  select: function (value) {
	  const index = this.weeks.indexOf(value)
	  if (index === -1) {
      this.weeks.push(value)
    } else {
      this.weeks.splice(index, 1)
    }
		this.display()
  },

  display: function () {
	  const nums = this.input.nextSibling.querySelectorAll('.week-number')
		nums.forEach(num => {
		  num.classList.remove('week-selected')
		})
		this.weeks.forEach(num => {
      if (WeekPicker.input.nextSibling.querySelector('.week-'+num)) {
        WeekPicker.input.nextSibling.querySelector('.week-'+num).classList.add('week-selected')
      }
		})
		this.input.value = this.weeks.length > 0 ? this.weeks.join(',') : ''
    if (this.weeks.length == 52) {
  	  document.querySelector('.weeks-selectall').innerHTML = 'Unselect All'
    } else {
  	  document.querySelector('.weeks-selectall').innerHTML = 'Select All'
    }
  },

  weeksInMonth: function (year, month) {
	  const first = new Date(year, month, 1)
	  const last  = new Date(year, month + 1, 0)
	  let dayOfWeek = first.getDay()
	  if (dayOfWeek === 0 || dayOfWeek === 1) dayOfWeek = 7
	  let days = dayOfWeek + last.getDate() - 1
	  return Math.ceil(days / 7) - 1
  }
}

